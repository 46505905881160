<script>
import { defineComponent, computed, ref, toRefs } from "vue";
import { merge } from "lodash-es";
import { useRouter } from "vue-router/composables";
import { shaKey } from "@/js/helper";
import { vuetifyConfig } from "@/plugins/vuetify";
import { use, graphic } from "echarts/core";
import { CustomChart } from "echarts/charts";
import { TooltipComponent, GridComponent, DataZoomComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import acctopusLightTheme from "@/js/acctopus-light.json";
import testStatus, { NOT_TESTING } from "@/cfg/testStatus";
import { GROUP_START_TYPES, SCHEDULED_START_TYPE } from "@/composition/explorer-tests/config"

use([TooltipComponent, GridComponent, CustomChart, CanvasRenderer, DataZoomComponent]);

const DAY = 84600000;
const TO_TIME = +new Date();
const FROM_TIME = TO_TIME - DAY;
const TEST_STATUS_MAP_BY_ID = new Map(testStatus.map(t => [t.id, t]));
TEST_STATUS_MAP_BY_ID.set(NOT_TESTING.id, NOT_TESTING);

const grid = {
  left: 16,
  right: 16,
  top: 2,
  bottom: 2,
};

export default defineComponent({
  name: "ExplorerStatusChartMini",

  components: {
    VChart
  },

  props: {
    explorerName: {
      type: String,
      default: "",
    },
    statisticsData: {
      type: Array,
      required: true,
    },
    toMs: {
      type: Number,
      default: TO_TIME,
    },
    fromMs: {
      type: Number,
      default: FROM_TIME
    },
    echartOptions: {
      type: Object,
      default: () => ({}),
    }
  },

  setup(props) {
    const chart = ref(null);
    const router = useRouter();
    const {
      toMs,
      fromMs,
      statisticsData,
      explorerName,
    } = toRefs(props);

    const tooltip = {
      show: true,
      appendToBody: true,
      trigger: "item",

      formatter: params => {
        // For regular data
        if (params.data && Array.isArray(params.data.value)) {
          const startTime = new Date(params.value[1]);
          const endTime = new Date(params.value[2]);
          const testCaseName = params.value[3];
          const resultInfo = params.value[4];

          const formatTime = function (date) {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
          };

          const fromTime = formatTime(startTime);
          const toTime = formatTime(endTime);

          let tooltip = `
          <b>${explorerName.value}</b><br/>
          ${testCaseName} | ${fromTime} - ${toTime}`;

          if (params.data.startType) {
            tooltip += `<br/> Type: ${params.data.startType}`;
          }

          if (params.data?.userFullName) {
            tooltip += ` | Started by: ${params.data.userFullName}`;
          }

          tooltip += `<br/> ${params.marker}${params.name}`;

          if (resultInfo) {
            return tooltip + ` | ${resultInfo}`;
          }

          return tooltip;
        } else {
          return `<b>${explorerName.value}</b>`;
        }
      }
    };

    function createSeriesDataEntry(statisticEntry) {
      const {
        status: _status,
        endTime: endTimeSec,
        startTime: startTimeSec,
        name,
        id: testId,
        groupStartType,
        resultInfo,
        userFullName,
      } = statisticEntry;

      let status;
      if (_status !== null) {
        status = parseInt(_status);
      } else {
        status = _status;
      }

      // running test is 1
      // dont display
      if (status === 1) {
        return null;
      }

      let [endTimeMs, startTimeMs] = [endTimeSec * 1000, startTimeSec * 1000];


      if (startTimeMs - fromMs.value < 0) {
        return null;
      }

      if (endTimeMs <= 0) {
        endTimeMs = toMs.value;
      }

      const testStatus = TEST_STATUS_MAP_BY_ID.get(status);

      return {
        value: [0, startTimeMs, endTimeMs, name, resultInfo, testId],
        name: testStatus.text,
        startType: GROUP_START_TYPES[groupStartType],
        ...(groupStartType === SCHEDULED_START_TYPE ? {} : {userFullName}),
        itemStyle: {
          normal: {
            color: vuetifyConfig.theme.themes.light[testStatus.color],
          }
        }
      }
    }

    const renderItem = (params, api) => {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size([0, 1])[1] * 0.7;
      const rectShape = graphic.clipRectByRect(
          {
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height,
          },
          {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
          }
        );

        return (
          rectShape && {
            type: "rect",
            transition: ["shape"],
            shape: rectShape,
            style: {
            ...api.style(),
          }
        }
      );
    }

    const data = computed(() => statisticsData.value?.map(createSeriesDataEntry).filter(Boolean));
    const option = computed(() => merge({
      tooltip,
      grid,
      xAxis: {
        show: false,
        type: "time",
        min: fromMs.value,
        max: toMs.value,
        scale: true,
        interval: 4 * 3600000,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: {
            day: '{dd}',
            hour: '{HH}:{mm}',
            minute: '{HH}:{mm}',
            second: '{HH}:{mm}',
            millisecond: '{HH}:{mm}',
            none: '{HH}:{mm}',
          },
          showMinLabel: true,
          showMaxLabel: true,
          alignMinLabel: 'center',
          alignMaxLabel: 'center'
        }
      },
      yAxis: { data: [] },
      series: [
        {
          type: "custom",
          renderItem,
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [1, 2],
            y: 0
          },
          z: 10,
          // DATA TO DISPLAY
          data: data.value.filter(i => i.name !== NOT_TESTING.text),
        },
        {
          type: "custom",
          renderItem,
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [1, 2],
            y: 0
          },
          z: 1,
          data: data.value.filter(i => i.name === NOT_TESTING.text)
        },
        {
          type: "custom",
          renderItem(params) {
            return {
              type: "rect",
              shape: {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
              },
              style: {
                fill: "transparent"
              }
            };
          },
          z: 0, // Below everything else
          data: [{ name: "Offline" }],
          tooltip: {
            show: true,
            appendToBody: true,
            formatter: () => {
              return `<b>${explorerName.value}</b><br/>Offline`;
            }
          }
        },
        ...(data.value.length ? [] : [{
          type: 'custom',
          renderItem(params) {
            return {
              type: 'rect',
              shape: {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
              },
              style: {
                fill: 'rgba(0,0,0,0.01)'
              }
            };
          },
          data: [{ name: explorerName.value }],
          tooltip: {
            show: true,
            appendToBody: true,
            formatter: () => `<b>${explorerName.value}</b>`
          }
        }])
      ],
    }, props.echartOptions)
    );

    const openTestInfo = (e) => {
      const id = e.data.value[e.data.value.length - 1];
      if (!id) {
        return;
      }
      const idKey = shaKey(id.toString());
      const route = router.resolve({ name: "test-info", params: { id, idKey } })
      window.open(route.href, "_blank");
    };

    const initOptions = {
      useCoarsePointer: true,
    };

    return {
      acctopusLightTheme,
      openTestInfo,
      chart,
      option,
      initOptions,
    };
  }
});
</script>

<template>
  <div class="explorer-status-chart-mini">
    <VChart
      class="explorer-status-chart-mini__chart"
      ref="chart"
      :init-options="initOptions"
      :option="option"
      :theme="acctopusLightTheme"
      @click="(e) => openTestInfo(e)"
    />
  </div>
</template>

<style lang="scss">
.explorer-status-chart-mini {
  height: 100%;
  width: 100%;
}
</style>
