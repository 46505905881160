<script setup>
import { onMounted, defineProps, ref, inject, onUnmounted } from "vue";
import { cockpitExplorerService } from "@/api";
import { DEFAULT_PARAMS } from "@/composition/tables/use-table-api";
import ExplorerStatusChartMini from "./ExplorerStatusChartMini.vue";
import { explorerAliasName } from "@/composition/explorers/use-explorers";

const rand = Math.random().toString(32).slice(2,8);

const props = defineProps({
  // { to: number(sec), from: number(sec) }
  range: {
    type: Object,
    required: true,
  }
});

const explorerIdsNameMap = ref({});
const chartsData = ref([]);
const isLoading = ref(true);

const {
  addToRefreshQueue,
  removeFromRefreshQueue,
} = inject("DashboardView2");

const fetchExplorersIds = async (_range) => {
  const res = await cockpitExplorerService.v2.getExplorersTable({
    ...DEFAULT_PARAMS,
    to: _range.to,
    from: _range.from,
    mode: "dashboard",
    sortBy: "alias",
    devicePool: 0,
    status: -1,
    online: -1,
    tags: "",
  });

  explorerIdsNameMap.value = res.records.reduce((acc,expl) => {
    acc[expl.id] = explorerAliasName(expl);
    return acc;
  }, {});
};

const fetchExplorersStatistics = async (_range) => {
  const [...data] = await Promise.all(
    Object.keys(explorerIdsNameMap.value).map((id) => cockpitExplorerService.v1.getExplorerTestsStatistics({
      id: parseInt(id),
      from: _range.from,
      to: _range.to,
      context: "project,other",
    }))
  );
  chartsData.value = data;
};

addToRefreshQueue(`explorers-tests-charts-list-${rand}`, async ({ isRangeChange }) => {
  if (isRangeChange) {
    isLoading.value = true;
    await fetchExplorersIds(props.range);
  }
  try {
    await fetchExplorersStatistics(props.range);
  } finally {
    isLoading.value = false;
  }
});

onMounted(async () => {
  isLoading.value = true;
  try {
    await fetchExplorersIds(props.range);
    await fetchExplorersStatistics(props.range);
  } finally {
    isLoading.value = false;
  }
});
onUnmounted(() => removeFromRefreshQueue(`explorers-tests-charts-list-${rand}`))
</script>

<template>
  <article class="explorers-tests-charts-list">
    <h2 class="explorers-tests-charts-list__title">
      Explorers testing
    </h2>

    <div class="explorers-tests-charts-list__chart-list-container">
      <ul class="explorers-tests-charts-list__chart-list">
        <template v-if="isLoading">
          <li 
            v-for="i of 10"
            :key="i"
            class="explorers-tests-charts-list__chart-list-item"></li>
        </template>

        <template v-else>
          <li 
            v-for="([id, name], i) of Object.entries(explorerIdsNameMap)"
            class="explorers-tests-charts-list__chart-list-item"
            :key="id"
          >
            <ExplorerStatusChartMini
              :explorer-name="name"
              :to-ms="range.to * 1000"
              :from-ms="range.from * 1000"
              :statistics-data="chartsData[i]"
            />
          </li>
        </template>
      </ul>
    </div>
  </article>
</template>

<style lang="scss">
.explorers-tests-charts-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    font-size: 1.25rem;
    text-align: center;
    padding: 0 1rem;
  }

  &__chart-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: hidden;
  }

  & &__chart-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1px;
    list-style: none;
    padding: 0;
    overflow-y: auto;
    min-height: 0;

    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  &__chart-list-item {
    flex: 1;
    // min-height: 40px;
    width: 100%;

    @include dark-inline-skeleton($percent: 100);
  }
}
</style>
