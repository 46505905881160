<script setup>
import { defineProps, computed } from "vue";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  ToolboxComponent,
  GraphicComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { merge } from "lodash-es";
import { vuetifyConfig } from "@/plugins/vuetify";
import { generateDummyLineData } from "@/composition/dashboard/chart-utils";

use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  CanvasRenderer,
  GraphicComponent,
]);

const props = defineProps({
  echartOptions: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  categoriesMap: {
    // Vue 2 doesn't treat Map's, but this values stays static
    type: Map,
    default: () => new Map,
  },
});

const dummyData = generateDummyLineData();

const createSeriesData = (data) => {
  if (!Object.keys(data).length) {
    return { series: [], max: 0 };
  }

  const series = [];
  let maxValue = 0;

  // [2, [[123412341234, 2], [2341341234, 3]]]
  for (const [testStatusId, timeLineData] of Object.entries(data)) {
    const intId = parseInt(testStatusId);
    if (!props.categoriesMap.has(intId)) {
      continue;
    }

    const { text, color } = props.categoriesMap.get(intId);

    series.push({
      name: text,
      type: "line",
      smooth: true,
      showSymbol: false,
      symbolSize: 0,
      color: vuetifyConfig.theme.themes.light[color],
      data: timeLineData,
    });
    maxValue = timeLineData.reduce((lastMax, [, n]) => Math.max(lastMax, n), maxValue);
  }

  return {
    series,
    max: maxValue,
  };
};

const isDummyData = computed(() => {
  return !props.data || Object.keys(props.data).length === 0;
});

const options = computed(() => {
  const dataToUse = isDummyData.value ? dummyData : props.data;
  const { series, max: maxYAxisVal } = createSeriesData(dataToUse);

  return merge(
    {
      title: {
        left: 0,
      },
      legend: {
        type: "scroll",
        left: 0,
        icon: "pin",
        backgroundColor: "transparent",
        bottom: 0,
      },
      tooltip: {
        show: true,
        trigger: "axis",
        appendToBody: true,
      },
      xAxis: {
        type: "time",
        axisLabel: {
          formatter: {
            day: "{dd}.{MM}",
            hour: "{HH}:{mm}",
            minute: "{HH}:{mm}",
            second: "{HH}:{mm}",
            millisecond: "{HH}:{mm}",
            none: "{HH}:{mm}",
          },
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: maxYAxisVal,
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          formatter: (v) => {
            if (v === 0 || v === maxYAxisVal) {
              return v;
            } else {
              return "";
            }
          },
        },
      },
      series,
      grid: {
        left: 50,
        top: 35,
        right: 15,
        bottom: 45,
      },
      toolbox: {
        right: 10,
        feature: {
          saveAsImage: {
            show: true,
          },
        },
      },
      graphic: isDummyData.value ? [
            {
              type: "text",
              left: "center",
              top: "40%",
              style: {
                text: "No data in the selected\ntime range and filters",
                font: "bold 14px Arial",
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: [3, 10, 5, 10],
                lineHeight: 20,
              },
              z: 8,
            },
          ]
        : [],
    }, isDummyData.value ? {
        tooltip: {
          show: false
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false
            }
          }
        },
        silent: true,
        animation: false,
        legend: {
          show: false,
        },
        smooth: true,
        lineStyle: {
          opacity: 0.2,
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              opacity: 0.2,
            },
          },
        }

      } : {},
    props.echartOptions
  );
});
</script>

<template>
  <div
    ref="container"
    class="line-chart"
  >
    <VChart
      class="line-chart__chart"
      ref="chart"
      :option="options"
      :autoresize="true"
    />
  </div>
</template>

<style lang="scss">
.line-chart {
  height: 100%;
  width: 100%;
  position: relative;

  &__chart {
    width: 100%;
    height: 100%;
  }
}
</style>
@/composition/dashboard/chart-utils
