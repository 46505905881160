<script setup>
import { defineProps, computed } from "vue";
import AnalogClock from "@/components/basic/AnalogClock.vue";

const DAYS = {
  de: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
};

const props = defineProps({
  clockSize: {
    type: Number,
    default: 320,
  },
  lang: {
    type: String,
    default: "de",
  }
});

const now = new Date();

const dayOfWeek = computed(() => DAYS[props.lang][now.getDay()]);
const formattedDate = computed(() => {
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const year = now.getFullYear();
  return `${day}.${month}.${year}`;
});

</script>

<template>
  <article class="current-time-block">
    <h2 class="current-time-block__title">
      {{ dayOfWeek }}
    </h2>

    <div class="current-time-block__clock">
      <AnalogClock :size="clockSize" />
    </div>

    <p class="current-time-block__date">
      {{ formattedDate }}
    </p>
  </article>
</template>

<style lang="scss">
.current-time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: space-evenly;
  height: 100%;

  & &__date {
    margin-bottom: 0;
  }
}
</style>
