<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  rowHeaders: {
    type: Array,
    required: true
  },
  tableData: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const getRowHeaderName = (index) => {
  return props.rowHeaders[index]?.name || '';
};

const totalResults = computed(() => {
  return {
    overall: props.tableData.reduce((sum, row) => sum + (Number(row.overallTest) || 0), 0),
    homeNetwork: props.tableData.reduce((sum, row) => sum + (Number(row.homeNetworkTest) || 0), 0),
    intlRoaming: props.tableData.reduce((sum, row) => sum + (Number(row.intlRoamingTest) || 0), 0)
  };
});

const totalErrors = computed(() => {
  return {
    overall: props.tableData.reduce((sum, row) => sum + (Number(row.overallError) || 0), 0),
    homeNetwork: props.tableData.reduce((sum, row) => sum + (Number(row.homeNetworkError) || 0), 0),
    intlRoaming: props.tableData.reduce((sum, row) => sum + (Number(row.intlRoamingError) || 0), 0)
  };
});

const topHeaders = ["Total", "Home Network", "International Roaming"];
const columns = ["Results", "Errors"];
const ROW_HEADERS_WIDTH = 180;
</script>

<template>
  <div class="mgmt-summary-table">
    <table class="mgmt-summary-table__table">
      <colgroup>
        <col :style="{ width: `${ROW_HEADERS_WIDTH}px` }"> <!-- Row headers column -->

        <col
          v-for="i of topHeaders.length * columns.length"
          :key="i"
          :style="{ width: `${ROW_HEADERS_WIDTH / 2}px` }"
        >
      </colgroup>

      <thead class="mgmt-summary-table__header-container">
        <tr class="mgmt-summary-table__header-row mgmt-summary-table__header-row--period">
          <th 
            class="mgmt-summary-table__header-cell mgmt-summary-table__header-cell--period" 
            :colspan="topHeaders.length * columns.length + 1"
          >
            <slot name="title" />
          </th>
        </tr>

        <tr class="mgmt-summary-table__header-row mgmt-summary-table__header-row--groups">
          <th class="mgmt-summary-table__header-cell"></th>

          <th
            v-for="topHeader in topHeaders"
            :key="topHeader"
            class="mgmt-summary-table__header-cell"
            :colspan="columns.length"
          >
            {{ topHeader }}
          </th>
        </tr>
        <tr class="mgmt-summary-table__header-row mgmt-summary-table__header-row--columns">
          <th class="mgmt-summary-table__header-cell"></th>

          <template
            v-for="i of topHeaders.length"
          >
            <th
              v-for="(column, ci) in columns"
              :key="(ci + Math.random()) * i"
              class="mgmt-summary-table__header-cell"
            >
              {{ column }}
            </th>
          </template>
        </tr>
      </thead>

      <tbody v-if="!isLoading">
        <tr
          v-for="(rowData, index) in tableData" :key="index" 
          class="mgmt-summary-table__row" 
        >
          <td class="mgmt-summary-table__row-header">{{ getRowHeaderName(index) }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.overallTest }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.overallError }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.homeNetworkTest }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.homeNetworkError }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.intlRoamingTest }}</td>
          <td class="mgmt-summary-table__data-cell">{{ rowData.intlRoamingError }}</td>
        </tr>

        <tr class="mgmt-summary-table__row mgmt-summary-table__row--totals">
          <td class="mgmt-summary-table__row-header mgmt-summary-table__row-header--total"></td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalResults.overall }}</td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalErrors.overall }}</td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalResults.homeNetwork }}</td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalErrors.homeNetwork }}</td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalResults.intlRoaming }}</td>
          <td class="mgmt-summary-table__data-cell mgmt-summary-table__data-cell--total">{{ totalErrors.intlRoaming }}</td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr
          v-for="(rh, i) in rowHeaders"
          :key="`skeleton-${i}`" 
          class="mgmt-summary-table__row"
        >
          <td class="mgmt-summary-table__row-header">
            {{ rh.name }}
          </td>

          <template
            v-for="i of topHeaders.length"
          >
            <td
              v-for="(_, ci) in columns"
              :key="( ci + Math.random() ) * i"
              class="mgmt-summary-table__data-cell"
            >
              <span class="mgmt-summary-table__data-cell-skeleton"></span>
            </td>
          </template>
        </tr>

        <tr class="mgmt-summary-table__row mgmt-summary-table__row--totals">
          <td class="mgmt-summary-table__row-header mgmt-summary-table__row-header--total"></td>

          <template
            v-for="i of topHeaders.length"
          >
            <td
              v-for="(_, ci) in columns"
              :key="( ci + Math.random() ) * i"
              class="mgmt-summary-table__data-cell"
            >
              <span class="mgmt-summary-table__data-cell-skeleton"></span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@mixin table-cell-border() {
  border: 1px solid #ddd; // Define all borders explicitly
}

@mixin table-cell() {
  padding: 2px 4px;
  background-clip: padding-box;
  @include table-cell-border();
}

.mgmt-summary-table {
  $root: &;

  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  
  &__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  &__header-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  &__header-row {
    &--period {
      background-color: $primary;
      color: white;
    }
    
    &--groups {
      background-color: lightgray;

      & #{$root}__header-cell {
        @include table-cell-border();
        border-right: 1px solid black !important;
      }
    }
    
    &--columns {
      background-color: white;

      & #{$root}__header-cell:nth-child(odd) {
        @include table-cell-border();
        border-right: 1px solid black !important;
      }

      & #{$root}__header-cell:nth-child(even) {
        @include table-cell-border();
      }
    }
  }
  
  &__header-cell {
    position: relative;
    text-align: center;
    font-weight: bold;
    background-color: inherit;
    z-index: 1;
    @include table-cell();
    
    &--period {
      padding: 10px;
      border: 0;
    }
  }
  
  &__row {
    background-color: white;
    transition: background-color .2s;

    &:hover {
      background-color: $table-border-base;
    }

    &--totals {
      background-color: transparent;
      font-weight: bold;
    }

    & td:nth-child(odd) {
      border-right: 1px solid black;
    }
  }
  
  &__row-header {
    font-weight: bold;
    text-align: left;
    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    @include table-cell();

    &:not(&--total) {
      border-right: 1px solid black;
    }

    &--total {
      background-color: transparent;
    }
  }
  
  &__data-cell {
    text-align: center;
    @include table-cell();
    
    &--total {
      font-weight: bold;
      background-color: transparent;
    }
  }

  &__data-cell-skeleton {
    width: 50%;
    max-width: 50%;
    @include dark-text-skeleton($length: 3);
  }
}
</style>
