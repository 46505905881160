<script setup>
import { ref, defineProps, computed } from "vue";
import XDialog from "@/components/basic/XDialog.vue";
import XTextField from "@/components/basic/XTextField.vue";
import XBtn from "@/components/basic/XBtn.vue";
import XCheckbox from "@/components/basic/XCheckbox.vue";
import { useStore } from "@/store";
import { TEST_STEP_STATUS_MAP_SHORT_TXT } from "@/cfg/testStepStatus";
import { getContrastTextColor } from "@/js/helper";
import requests from "@/js/requests";
import moment from "moment";
import chartService from '@/js/services/ChartService';
import statisticsActiveStep from '@/cfg/statisticsActiveStep.json';

const showAuditDialog = ref(false);
const auditInfo = ref("");
const hideInStatistics = ref(false);
const store = useStore();
const auditor = ref("");
const lastAudit = ref("");

const props = defineProps({
  step: {
    type: String,
    required: true,
  },
  statusCode: {
    type: String,
    required: true,
  },
  nId: {
    type: String,
    required: true,
  },
  stepType: {
    type: String,
    required: true,
  }
});

const statusKey = parseInt(props.statusCode);
const statusData = TEST_STEP_STATUS_MAP_SHORT_TXT.get(statusKey) || { color: "#000" };
const buttText = getContrastTextColor(String(statusData.color));


const enabledSteps = computed(() => !statisticsActiveStep.includes(props.stepType));

const fetchAuditInfo = async () => {
  try {
    const response = await requests.frameworkAxiosRequest({
      method: "GET",
      url: "serve.php?f=testing&f2=testInfoTable",
      dataType: "json",
      params: {
        function: "getAuditInfo",
        key: props.nId,
        requestType: "ajax",
      },
    });
    if (response.data?.result?.json) {
      const data = response.data.result.json;
      auditInfo.value = data.auditInfo || "";
      hideInStatistics.value = data.statisticsHide || false;
      auditor.value = data.auditor || "";
      lastAudit.value = data.lastAudit || "";
    }

  } catch (error) {
    console.error("Error fetching audit info:", error);
  } finally {
    showAuditDialog.value = true;
  }
};

const saveAuditInfo = async () => {
  const AuditInfo = {
    auditInfo: auditInfo.value,
    statisticsHide: hideInStatistics.value,
    auditor: store.state.user?.firstName || "Unknown",
    lastAudit: moment().format("DD.MM.YYYY HH:mm"),
  };

  try {
    await requests.frameworkAxiosRequest({
      method: "POST",
      url: "serve.php?f=testing&f2=testInfoTable",
      dataType: "json",
      data: {
        function: "updateAuditInfo",
        info: JSON.stringify(AuditInfo),
        key: props.nId,
        requestType: "ajax",
      },
    });

    await chartService.postAudit(props.nId, hideInStatistics.value);

    store.commit("setAuditData", { nId: props.nId, data: AuditInfo });
    showAuditDialog.value = false;

  } catch (error) {
    console.error("Error saving audit info:", error);
    showAuditDialog.value = true;
  }
};
</script>

<template>
  <div class="ma-1">
    <XBtn
        elevation="0"
        :color="String(statusData.color)"
        :textColor="buttText"
        text="Audit"
        @click="fetchAuditInfo"
        small
    />

    <XDialog
        :title="'Audit: ' + step"
        :value="showAuditDialog"
        width="500"
        @input="showAuditDialog = false"
        @click:outside="showAuditDialog = false"
    >
      <template #dialog-content>
        <XTextField
            v-model="auditInfo"
            label="Audit Info"
            class="mb-5"
        />
        <XCheckbox
            v-model="hideInStatistics"
            label="Hide Statistics"
            :disabled="enabledSteps"
        />
        <div class="mt-3" v-if="auditor">
          Auditor:  {{ auditor }}
        </div>
        <div v-if="lastAudit">
          Last Audit:  {{ lastAudit }}
        </div>
        <div class="mt-10" style="display: flex; gap: 10px;">
          <XBtn
              text="Save"
              color="save"
              @click="saveAuditInfo"
          />
          <XBtn
              text="Cancel"
              color="secondary"
              @click="showAuditDialog = false"
          />
        </div>
      </template>
    </XDialog>
  </div>
</template>