<script setup>
  import { inject, onBeforeMount, defineProps } from "vue";
  import { UI_TABS_PROVIDE_KEY } from "@/composition/tabs/use-tabs";

  const { activeTab, addTab } = inject(UI_TABS_PROVIDE_KEY)
  // as {
  //   activeTab: Ref<string>;
  //   addTab: (data: TabData) => void;
  // };

  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  })

  onBeforeMount(() => {
    addTab({
      name: props.name,
      isDisabled: props.isDisabled,
      isActive: props.isActive,
    });
  });
</script>

<template>
  <div
    v-if="activeTab === name"
    :key="name"
    class="ui-tab-item"
  >
    <slot />
  </div>

  <div
    v-else
    :key="`${name}-blank`"
  />
</template>

<style lang="scss">
  .ui-tab-item {
    flex: 1;
    height: 100%;
  }
</style>
