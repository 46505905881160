<script setup>
import { computed, watch } from "vue";
import { defineProps } from "vue";
import { useStore } from "@/store";

const store = useStore();
const props = defineProps(["row"]);

const allowedStatus = computed(() => {
  return ['2', '3', '7', '8'].includes(props.row.status);
});

const auditData = computed(() => store.state.auditData[props.row.n_id] || {});

const info = computed(() => {
  try {
    return JSON.parse(props.row.c_auditInfo) || {};
  } catch (e) {
    console.error("Error parsing audit info:", e);
    return {};
  }
});

watch(() => props.row.n_id, (newNId) => {
  if (newNId && newNId !== props.row.n_id) {
    store.dispatch("fetchAuditData", newNId);
  }
}, { immediate: true });

const AuditTitle = computed(() => {
  const auditInfo = auditData.value.auditInfo || info.value?.auditInfo || "";
  const auditor = auditData.value.auditor || info.value?.auditor || "";
  const lastAudit = auditData.value.lastAudit || info.value?.lastAudit || "";
  const statisticsHide = (auditData.value.statisticsHide || info.value?.statisticsHide) ? "Hide" : "";

  const titleArray = [];

  if (auditInfo) titleArray.push(`Audit Info: ${auditInfo}`);
  if (statisticsHide) titleArray.push(`Statistics: ${statisticsHide}`);
  if (auditor) titleArray.push(`Auditor: ${auditor}`);
  if (lastAudit) titleArray.push(`Last Audit: ${lastAudit}`);

  return titleArray.length > 0 ? `Audited\n${titleArray.join('\n')}` : 'No Audit Info';
});

const isAudited = computed(() => {
  const isNotEmpty = (value) => value && Object.keys(value).length > 0;

  return isNotEmpty(props.row.c_auditInfo) || isNotEmpty(auditData?.value?.auditor);
});

const stencilInfoExist = computed(() => {
  return Object.keys(props.row["c_stencil_info"]).length > 0;
});

const stencilTitle = computed(() => {
  return "   " + stencilName(props.row["c_stencil_info"].test_name) + "   |   Layer " + stencilLayer(props.row["c_stencil_info"].layer) + "  ";
});

const stencilLayer = (val) => val + 1;

const stencilName = (stencil) => {
  const [name] = stencil.split(':');
  if (name.toLowerCase() !== 'stencil') {
    stencil = "Stencil:" + stencil;
  }
  return stencil;
};
</script>
<template>
  <div>
    <!-- Stencil Info Section -->
    <div v-if="stencilInfoExist">
      <v-icon :title="stencilTitle" style="cursor: pointer">mdi-stamper</v-icon>
    </div>

    <!-- Audit Info Section -->
    <div v-if="allowedStatus && store.state.permissions.orgaRole === 1 && row.packetDirection === 'packetIn' && isAudited">
      <v-icon :title="AuditTitle" style="cursor: pointer">mdi-text-box-check-outline</v-icon>
    </div>
  </div>
</template>