var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"ui-tabs-container",style:(`--underscore-left: ${_setup.underscoreLeft};
     --underscore-width: ${_setup.underscoreWidth}; 
     --underscore-trans-style: ${_setup.underscoreTransitionStyle};
     --scrollable-width: ${_setup.getScrollableWidth};`)},[_c('div',{class:{
      'ui-tabs-container__controls': true,
      [`ui-tabs-container__controls--${_setup.props.alignTabs}`]: _vm.alignTabs,
      'ui-tabs-container__controls--fixed-tabs-box': _setup.props.tabsAreScrollable,
    }},[_c('div',{ref:"scrollableRef",staticClass:"ui-tabs-container__scrollable",on:{"wheel":(e) => _setup.handleUserScroll(e)}},[_vm._l((_setup.tabsDataList),function(tab){return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],key:tab.name,ref:"tabButtons",refInFor:true,class:{
          'ui-tabs-container__btn': true,
          'ui-tabs-container__btn--active': _setup.activeTab === tab.name,
          'ui-tabs-container__btn--disabled': tab.isDisabled,
        },attrs:{"disabled":tab.isDisabled},on:{"click":() => _setup.onTabClick(tab.name),"mouseenter":() => _setup.onTabHover(tab.name)}},[_vm._t(`tab-btn(${_setup.formatTabName(tab.name)})`,function(){return [_vm._v(" "+_vm._s(tab.name)+" ")]})],2)}),_c('div',{staticClass:"ui-tabs-container__underscore"})],2),_c('div',{staticClass:"ui-tabs-container__right-controls"},[_vm._t("right-controls")],2)]),_c('div',{staticClass:"ui-tabs-container__above-tabs"},[_vm._t("above-tabs")],2),_c('TransitionGroup',{staticClass:"ui-tabs-container__body",attrs:{"tag":"div","name":_setup.props.animated ? _setup.transitionName : ''}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }