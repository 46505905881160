<script
  setup
>
  import { ref, provide, readonly, onMounted, nextTick, defineProps, defineEmits, toRefs, computed } from "vue";
  import { sleep } from "@/js/helper";
  import { useTabs, UI_TABS_PROVIDE_KEY } from "@/composition/tabs/use-tabs";

  const TRANSITION_GROUP_NAMES = {
    SLIDE_LEFT: "slide-left",
    SLIDE_RIGHT: "slide-right",
  };
  const UNDERSCORE_TRNSITION_STYLE = "left 0.3s ease, width 0.3s ease";

  const props = defineProps({
    onHover: {
      type: Boolean,
      default: false,
    },

    alignTabs: {
      type: String,
      default: "start",
    },

    animated: {
      type: Boolean,
      default: false,
    },

    activeTab: {
      type: String,
      default: "",
    },

    tabsAreScrollable: {
      type: Boolean,
      default: false,
    }
  });

  const emit = defineEmits(["update:activeTab"]);

  const {
    activeTab,
    tabsAreScrollable,
  } = toRefs(props);

  const { addTab, tabsDataList, currentTabIdx } = useTabs(activeTab);

  const transitionName = ref("");
  const underscoreLeft = ref("0px");
  const underscoreWidth = ref("0px");
  const tabButtons = ref([]);
  const scrollableRef = ref(null);
  const underscoreTransitionStyle = ref("");
  const getScrollableWidth = computed(() => {
    if (!tabsAreScrollable.value) {
      return "auto";
    }

    return Number.isInteger(tabsAreScrollable.value) ?
      `${tabsAreScrollable.value}px` 
      : tabsAreScrollable.value;
  });

  const formatTabName = (name) => {
    return name.toLowerCase().replace(/\s/g, "-");
  };

  const setInitalTab = () => {
    if (tabsDataList.value.length > 0) {
      const initialTab = tabsDataList.value.find((tab) => tab.isActive);
      if (initialTab) {
        emit("update:activeTab", initialTab.name);
      } else {
        emit("update:activeTab", tabsDataList.value[0].name);
      }
    }
  };

  const onTabClick = async (tabName) => {
    const idx = tabsDataList.value.findIndex((tab) => tab.name === tabName);

    if (props.animated) {
      if (idx > currentTabIdx.value) {
        transitionName.value = TRANSITION_GROUP_NAMES.SLIDE_LEFT;
      } else {
        transitionName.value = TRANSITION_GROUP_NAMES.SLIDE_RIGHT;
      }
    } else {
      transitionName.value = ""; // No transition
    }

    emit("update:activeTab", tabName);
    await scrollToActiveTab();
    moveUnderscore();
  };

  const onTabHover = (tabName) => {
    if (props.onHover) {
      onTabClick(tabName);
    }
  };

  const moveUnderscore = async () => {
    await nextTick();

    const tabs = tabButtons.value;
    if (tabs.length === 0 || currentTabIdx.value === -1) {
      return;
    }

    const currentTab = tabs[currentTabIdx.value];

    underscoreLeft.value = `${currentTab.offsetLeft}px`;
    underscoreWidth.value = `${currentTab.offsetWidth}px`;
  };

  const scrollToActiveTab = async ({ isSmooth = true } = {}) => {
    await nextTick();
    const scrollable = scrollableRef.value;
    const tabs = tabButtons.value;
    const currentTabEl = tabs[currentTabIdx.value];

    if (scrollable && currentTabEl) {
      const scrollPosition =
        currentTabEl.offsetLeft + currentTabEl.offsetWidth / 2 - scrollable.offsetWidth / 2;
      const options = {
        left: scrollPosition,
        top: 0,
      };

      if (isSmooth) {
        options.behavior = "smooth";
      }

      scrollable.scrollTo(options);
    }
  };

  const handleUserScroll = (e) => {
    if (!props.tabsAreScrollable) {
      e.preventDefault();
    }
  };

  const init = async () => {
    setInitalTab();
    await scrollToActiveTab({ isSmooth: false });
    await sleep(50);
    await moveUnderscore();

    if (props.animated) {
      transitionName.value = TRANSITION_GROUP_NAMES.SLIDE_LEFT;
    }

    underscoreTransitionStyle.value = UNDERSCORE_TRNSITION_STYLE;
  };

  provide(UI_TABS_PROVIDE_KEY, {
    activeTab: readonly(activeTab),
    addTab,
  });

  onMounted(() => {
    init();
  });
</script>

<template>
  <section 
    class="ui-tabs-container"
    :style="
      `--underscore-left: ${underscoreLeft};
       --underscore-width: ${underscoreWidth}; 
       --underscore-trans-style: ${underscoreTransitionStyle};
       --scrollable-width: ${getScrollableWidth};`
      "
  >
    <div
      :class="{
        'ui-tabs-container__controls': true,
        [`ui-tabs-container__controls--${props.alignTabs}`]: alignTabs,
        'ui-tabs-container__controls--fixed-tabs-box': props.tabsAreScrollable,
      }"
    >
      <div
        ref="scrollableRef"
        class="ui-tabs-container__scrollable"
        @wheel="(e) => handleUserScroll(e)"
      >
        <button
          v-for="tab of tabsDataList"
          ref="tabButtons"
          :key="tab.name"
          v-ripple
          :class="{
            'ui-tabs-container__btn': true,
            'ui-tabs-container__btn--active': activeTab === tab.name,
            'ui-tabs-container__btn--disabled': tab.isDisabled,
          }"
          :disabled="tab.isDisabled"
          @click="() => onTabClick(tab.name)"
          @mouseenter="() => onTabHover(tab.name)"
        >
          <slot :name="`tab-btn(${formatTabName(tab.name)})`">
            {{ tab.name }}
          </slot>
        </button>

        <div class="ui-tabs-container__underscore" />
      </div>

      <div class="ui-tabs-container__right-controls">
        <slot name="right-controls" />
      </div>
    </div>

    <div class="ui-tabs-container__above-tabs">
      <slot name="above-tabs" />
    </div>

    <TransitionGroup
      class="ui-tabs-container__body"
      tag="div"
      :name="props.animated ? transitionName : ''"
    >
      <slot />
    </TransitionGroup>
  </section>
</template>

<style lang="scss">
  $controls-height: 48px;
  $underscore-height: 2px;

  .ui-tabs-container {
    display: grid;
    grid-template-rows: $controls-height auto 1fr;
    height: 100%;

    &__controls {
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 50px;
      overflow: hidden;

      &--fixed-tabs-box {
        grid-template-columns: repeat(2, 1fr);
      }

      &--start {
        justify-self: start;
      }

      &--center {
        justify-self: center;
      }

      &--stretch {
        justify-self: stretch;
      }

      &--end {
        justify-self: end;
      }
    }

    &__scrollable {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__right-controls {
      grid-column: 2;
    }

    &__btn {
      position: relative;
      flex: 0 0 auto;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;

      color: rgba(0, 0, 0, 0.54);
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.0892857143em;
      text-transform: uppercase;
      line-height: normal;
      cursor: pointer;

      @include on-hover-effect();

      &--active {
        color: #2b5593;
      }
    }

    &__underscore {
      position: absolute;
      bottom: $underscore-height;
      left: var(--underscore-left);
      width: var(--underscore-width);
      border-bottom: $underscore-height solid #2b5593;
      transition: var(--underscore-trans-style);
    }

    &__body {
      position: relative;
      overflow: hidden;
    }
  }
</style>
