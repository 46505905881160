<script setup>
import { computed, ref, defineProps } from "vue";
import { use } from "echarts/core";
import { LineChart, PieChart } from "echarts/charts";
import {
  LegendComponent,
  TooltipComponent,
  DatasetComponent,
  GridComponent,
  GraphicComponent
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { merge } from "lodash-es";
import { vuetifyConfig } from "@/plugins/vuetify";
import { generateDummyLineData } from "@/composition/dashboard/chart-utils";

use([
  LegendComponent,
  TooltipComponent,
  DatasetComponent,
  GridComponent,
  LineChart,
  PieChart,
  CanvasRenderer,
  GraphicComponent
]);

const props = defineProps({
  lineData: {
    type: Object,
    default: () => ({}),
  },
  pieData: {
    type: Object,
    default: () => ({}),
  },
  categoriesMap: {
    // Vue 2 doesn't treat Map's, but this values stays static
    type: Map,
    default: () => new Map,
  },
  echartOptions: {
    type: Object,
    default: () => ({}),
  },
});

const container = ref(null);
const chart = ref(null);

const dummyPieData = {
  1: 100,
};

const dummyLineData = generateDummyLineData();

const isDummyData = computed(() => {
  const isEmpty = obj => !obj || Object.values(obj).every(arr => Array.isArray(arr) && arr.length === 0);
  return isEmpty(props.pieData) && isEmpty(props.lineData);
});

const createPieChartConfig = (pieData) => {
  if (!pieData || Object.keys(pieData).length === 0) return { data: [] };

  const chartPieData = Object.entries(pieData).map(([statusId, count]) => {
    const id = parseInt(statusId);
    if (!props.categoriesMap.get(id)) {
      return {
        name: `Unknown status ${id}`,
        value: count,
        itemStyle: {
          color: '#999999'
        }
      };
    }
    const colorId = props.categoriesMap.get(id).color;
    return {
      name: props.categoriesMap.get(id).text,
      value: count,
      itemStyle: {
        color: vuetifyConfig.theme.themes.light[colorId],
        opacity: isDummyData.value ? 0.2 :1,
      }
    };
  });

  return {
    type: "pie",
    radius: "40%",
    center: ["50%", "40%"],
    data: chartPieData,
    label: {
      formatter: function(params) {
        const nameStr = "{name|" + params.name + "}";
        const valueStr = "{value|" + params.value + "}";
        const percentStr = "{percent|: " + params.percent + "%}";

        return nameStr + "\n" + valueStr + percentStr;
      },
      rich: {
        name: {
          fontSize: 12,
          align: "left",
        },
        value: {
          fontSize: 12,
          align: "left",
        },
        percent: {
          fontSize: 11,
          color: "#0000008a",
          align: "left",
        }
      }
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)"
      }
    }
  };
};

const createLineSeriesData = (lineData) => {
  if (!lineData || Object.keys(lineData).length === 0) return { xAxis: [], series: [] };

  const timestamps = new Set();
  Object.values(lineData).forEach(statusData => {
    statusData.forEach(([timestamp]) => {
      timestamps.add(timestamp);
    });
  });

  const sortedTimestamps = Array.from(timestamps).sort((a, b) => a - b);

  const minYVal = 0;
  let maxYVal = 0;
  const series = Object.entries(lineData).map(([statusId, dataPoints]) => {
    const valueMap = new Map(dataPoints);
    const id = parseInt(statusId);

    if (!props.categoriesMap.get(id)) {
      return null;
    }

    const data = sortedTimestamps.map((timestamp) => {
      const value = valueMap.get(timestamp) || 0;
      maxYVal = Math.max(maxYVal, value);
      return [
        timestamp,
        value,
      ];
    });

    const colorId = props.categoriesMap.get(id).color;
    return {
      name: props.categoriesMap.get(id).text,
      type: "line",
      symbol: "none",
      smooth: true,
      data: data,
      itemStyle: {
        color: vuetifyConfig.theme.themes.light[colorId]
      },
      lineStyle: {
        width: 2
      },
      emphasis: {
        focus: 'series'
      }
    };
  }).filter(Boolean);

  return {
    timestamps: sortedTimestamps,
    series: series,
    yAxisLabels: {
      min: minYVal,
      max: maxYVal,
    },
  };
};

const options = computed(() => {

  const pieChartConfig = createPieChartConfig(isDummyData.value ? dummyPieData : props.pieData);
  const chartLineData = createLineSeriesData(isDummyData.value ? dummyLineData : props.lineData);

  return merge({
    title: {
      left: 0
    },
    legend: {
      icon: "pin",
      backgroundColor: "transparent",
      bottom: 2,
      left: 2,
      textStyle: {
        fontSize: 0,
      },
    },
    toolbox: {
      right: 10,
      feature: {
        saveAsImage: {
          show: true
        }
      }
    },
    tooltip: {
      appendToBody: true,
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "time",
      axisLabel: {
        show: false,
        // formatter: {
        //   day: '{dd}.{MM}',
        //   hour: '{HH}:{mm}',
        //   minute: '{HH}:{mm}',
        //   second: '{HH}:{mm}',
        //   millisecond: '{HH}:{mm}',
        //   none: '{HH}:{mm}',
        // },
      }
    },
    yAxis: {
      type: "value",
      min: chartLineData.yAxisLabels.min,
      max: chartLineData.yAxisLabels.max,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: (v) => {
          const { min, max } = chartLineData.yAxisLabels;
          if (v === min || v === max) {
            return v;
          } else {
            return "";
          }
        }
      }
    },
    grid: {
      top: "70%",
      bottom: 35,
    },
    graphic: isDummyData.value ? [

      {
        type: "text",
        left: "center",
        top: "30%",
        style: {
          text: "No data in the selected\ntime range and filters",
          font: "bold 14px Arial",
          textAlign: "center",
          backgroundColor: "rgba(255,255,255,0.9)",
          padding: [3, 10, 5, 10],
          lineHeight: 20,
        },
        z: 8,
      },

    ] : [],
    series: [
      pieChartConfig,
      ...chartLineData.series
    ]
  } ,isDummyData.value ? {
      tooltip: {
        show: false
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: false
          }
        }
      },
      silent: true,
      animation: false,
      legend: {
        show: false,
      },
      lineStyle: {
          opacity: 0.2,
      },
        xAxis: {
          axisLine: {
            lineStyle: {
              opacity: 0.2,
            },
          },
        }
  } : {} ,
      props.echartOptions);
});
</script>

<template>
  <div
    ref="container"
    class="pie-with-line-chart"
  >
    <VChart
      class="pie-with-line-chart__chart"
      ref="chart"
      :option="options"
      :autoresize="true"
    />
  </div>
</template>

<style lang="scss">
.pie-with-line-chart {
  height: 100%;
  width: 100%;
  position: relative;

  &__chart {
    width: 100%;
    height: 100%;
  }
}
</style>
@/composition/dashboard/chart-utils
