<script setup>
import { onMounted, defineProps, ref, onBeforeUnmount, defineEmits, computed, nextTick, watch } from "vue";
import { loadMapDependencies } from "@/js/map-loader";
import { vuetifyConfig } from "@/plugins/vuetify";
import { debounce } from "lodash-es";

const props = defineProps({
  mapOptions: {
    type: Object,
    default: () => ({}),
  },
  markers: {
    type: Array,
    required: true,
  },
  categoriesConfigMap: {
    type: Map,
    default: () => new Map,
  },
});
const emit = defineEmits(["marker-click"]);

const DOT_SIZE = 2;
let L = null;
let resizeObserver = null;
const mapContainer = ref(null);
const map = ref(null);
const markerLayer = ref(null);
const markersCache = new Map();

const markersByCategory = computed(() => {
  const result = new Map();

  for (const m of props.markers) {
    const statusId = parseInt(m.result);
    if (!result.has(statusId)) {
      result.set(statusId, []);
    }
    result.get(statusId).push(m);
  }

  return result;
});

const getColor = (colorName) => {
  return vuetifyConfig.theme.themes.light[colorName];
};

const getTooltipHTML = ({ explorer, testName }) => {
  if (!explorer) {
    return testName;
  }
  return `<b>${explorer}</b><br>${testName}`;
};

const createTooltip = (marker) => {
  if (!map.value) return;
  
  const statusId = parseInt(marker.result);

  if (!props.categoriesConfigMap.has(statusId)) {
    return;
  }

  const { text } = props.categoriesConfigMap.get(statusId);
  const { explorer = "" } = marker;
  
  return L.tooltip({ autoClose: true })
    .setLatLng([marker.lat, marker.lon])
    .setContent(getTooltipHTML({ explorer, testName: text }))
    .openOn(map.value);
};

const handleMarkerClick = (marker) => {
  emit("marker-click", marker);
};

const handleResize = debounce(() => {
  if (map.value) {
    map.value.invalidateSize();
  }
}, 100);

function getCircleSize(zoom) {
  return Math.max(DOT_SIZE, zoom * 0.5);
}

const renderMarkers = () => {
  const currentZoom = map.value.getZoom();
  const baseSize = getCircleSize(currentZoom);
  
  // Store IDs of current markers to detect removed ones
  const currentMarkerIds = new Set();
  
  const sortedStatusId = Array.from(markersByCategory.value.keys());
  sortedStatusId.sort((a, b) => b - a);

  for (const id of sortedStatusId) {
    const markers = markersByCategory.value.get(id);

    if (!props.categoriesConfigMap.has(id)) {
      continue;
    }
    
    const { color: vuetifyColorName  } = props.categoriesConfigMap.get(id);
    const colorString = getColor(vuetifyColorName);
    
    markers.forEach(m => {
      const markerId = m.id || `${m.lat}-${m.lon}`;
      currentMarkerIds.add(markerId);
      
      if (markersCache.has(markerId)) {
        // Marker already exists, just update its size
        const circle = markersCache.get(markerId);
        circle.__baseSize = baseSize;
        circle.setRadius(baseSize);
      } else {
        // Create new marker
        const circle = L.circleMarker([m.lat, m.lon], {
          radius: baseSize,
          fillColor: colorString,
          stroke: false,
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
          renderer: markerLayer.value || L.canvas({ padding: 0.5 })
        });
        
        circle.originalData = m;
        circle.__tooltip = null;
        circle.__baseSize = baseSize;

        circle.on("click", () => {
          handleMarkerClick(m);
        });
        circle.on("mouseover", () => {
          circle.setRadius(circle.__baseSize * 2);
          circle.__tooltip = createTooltip(m);
        });
        circle.on("mouseout", () => {
          map.value.closeTooltip(circle.__tooltip);
          circle.__tooltip = null;
          circle.setRadius(circle.__baseSize);
        });
        
        circle.addTo(map.value);
        markersCache.set(markerId, circle);
      }
    });
  }
  
  // Remove markers that are no longer in the data
  markersCache.forEach((circle, id) => {
    if (!currentMarkerIds.has(id)) {
      map.value.removeLayer(circle);
      markersCache.delete(id);
    }
  });
};

const rerenderOnZoomEnd = () => {
  const currentZoom = map.value.getZoom();
  const baseSize = getCircleSize(currentZoom);

  markersCache.forEach(circle => {
    circle.__baseSize = baseSize;
    circle.setRadius(baseSize);
  });
}

const init = async () => {
  map.value = L.map(mapContainer.value, { preferCanvas: true, ...props.mapOptions });

  L.tileLayer("http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png", {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
  }).addTo(map.value);

  await new Promise(resolve => {
    map.value.whenReady(resolve);
  });
  
  if (!markerLayer.value) {
    markerLayer.value = L.canvas({ padding: 0.5 });
  }
  renderMarkers();
  
  map.value.on("zoomend", rerenderOnZoomEnd);
  
  resizeObserver = new ResizeObserver(handleResize);
  resizeObserver.observe(mapContainer.value);
};


watch(() => props.markers, () => {
  renderMarkers();
});

onMounted(async () => {
  const { L: _L } = await loadMapDependencies();
  L = _L;
  await nextTick();
  init();
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
  
  if (map.value) {
    map.value.off("zoomend", rerenderOnZoomEnd);
    map.value.remove();
    map.value = null;
  }
});
</script>

<template>
  <div ref="mapContainer" class="leaflet-dots-map"></div>
</template>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";

.leaflet-dots-map {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
