<script setup>
import { defineProps, ref, inject, toRefs, computed, onMounted, onBeforeUnmount, onDeactivated, defineAsyncComponent } from "vue";
import PieWithLineChart from "@/components/dashboard/charts/PieWithLineChart.vue";
import CurrentTimeBlock from "@/components/dashboard/other/CurrentTimeBlock.vue";
import ExplorerUsageWidget from "@/components/dashboard/charts/ExplorerUsageWidget/index.vue"
import MgmtSummaryTable from "@/components/dashboard/other/MgmtSummaryTable.vue";
import { useOverviewTabData } from "@/composition/dashboard/use-overview-tab-data";
import { useDashboardFilters } from "@/composition/dashboard/use-dashboard-filters";
import { TEST_STEP_STATUS_LEGEND_TITLES, TEST_STEP_STATUS_MAP, DEFAULT_MAP_OPTIONS } from "@/composition/dashboard/constants";
import { useRouter } from "vue-router/composables";
import { shaKey } from "@/js/helper";
import { 
  useMgmtSummaryTable,
  ROW_HEADERS,
} from "@/composition/dashboard/use-mgmt-summary-table";

const LeafletDotsMap = defineAsyncComponent(() => import(/* webpackChunkName: "leaflet-dots-map" */ "@/components/dashboard/charts/LeafletDotsMap.vue"));

const props = defineProps({
  range: {
    type: Object,
    required: true,
  },
});

const isLoading = ref(true);

const {
  testResultsPieLine,
  testResultsMap,
  runAccumulativeFetch,
} = useOverviewTabData();

const { range } = toRefs(props);

const { rangeInSecForReq } = useDashboardFilters({ rangeRef: range });

const {
  currentPeriodTable,
  previousPeriodTable,
  updateTables: updateMgmtSummaryTable,
  isLoading: isTableLoading,
} = useMgmtSummaryTable();

const {
  addToRefreshQueue,
  removeFromRefreshQueue,
} = inject("DashboardView2");

const fetchDashboardData = async ({ isSilent = false } = {}) => {
  if (!isSilent) {
    isLoading.value = true;
  }
  try {
    await runAccumulativeFetch({ range: rangeInSecForReq.value });
  } finally {
    isLoading.value = false;
  }
}

const fetchData = async ({ isSilent = false } = {}) => {
  await Promise.all([
    updateMgmtSummaryTable({ range: rangeInSecForReq.value }, { isSilent }),
    fetchDashboardData({ isSilent }),
  ]);
};

addToRefreshQueue("mgmt-summary-data", async ({ isRangeChange }) => {
  await fetchData({ isSilent: !isRangeChange });
});

const router = useRouter();
const openTestPage = ({ testQueueID: id }) => {
  const idKey = shaKey(id.toString());
  const route = router.resolve({ name: "test-info", params: { id, idKey } })
  window.open(route.href, "_blank");
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const currentPeriodString = computed(() => {
  if (!props.range.from || !props.range.to) return '';

  return `${formatDate(props.range.from)} - ${formatDate(props.range.to)}`;
});

const previousPeriodString = computed(() => {
  if (!props.range.from || !props.range.to) return '';

  const durationMs = props.range.to.getTime() - props.range.from.getTime();

  const previousTo = new Date(props.range.from.getTime());
  const previousFrom = new Date(props.range.from.getTime() - durationMs);

  return `${formatDate(previousFrom)} - ${formatDate(previousTo)}`;
});


onMounted(async () => {
  await fetchData();
});

onDeactivated(() => {
  removeFromRefreshQueue("mgmt-summary-data");
});
onBeforeUnmount(() => {
  removeFromRefreshQueue("mgmt-summary-data");
});
</script>

<template>
  <section class="mgmt-summary-tab">
    <div class="mgmt-summary-tab__item-grid  mgmt-summary-tab__item-grid--overall-pie">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="testResultsPieLine.line"
        :pieData="testResultsPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Overall' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
        }"
      />
    </div>

    <div class="mgmt-summary-tab__item-grid  mgmt-summary-tab__item-grid--expl-widget">
      <ExplorerUsageWidget
        :range="rangeInSecForReq"
      />
    </div>

    <div class="mgmt-summary-tab__item-grid  mgmt-summary-tab__item-grid--map">
      <LeafletDotsMap
        v-if="!isLoading"
        :markers="testResultsMap.data"
        :categories-config-map="TEST_STEP_STATUS_MAP"
        :map-options="DEFAULT_MAP_OPTIONS"
        @marker-click="(marker) => openTestPage(marker)"
      />
    </div>

    <div class="mgmt-summary-tab__item-grid  mgmt-summary-tab__item-grid--clock">
      <CurrentTimeBlock lang="en" :clock-size="150" />
    </div>

    <div class="mgmt-summary-tab__item-grid mgmt-summary-tab__item-grid--table-1  mgmt-summary-tab__item-grid--scrollable">
      <MgmtSummaryTable
        :table-data="currentPeriodTable"
        :is-loading="isTableLoading"
        :row-headers="ROW_HEADERS"
      >
        <template #title>
          Current period {{ currentPeriodString }}
        </template>
      </MgmtSummaryTable>
    </div>

    <div class="mgmt-summary-tab__item-grid mgmt-summary-tab__item-grid--table-2 mgmt-summary-tab__item-grid--scrollable">
      <MgmtSummaryTable
        :table-data="previousPeriodTable"
        :is-loading="isTableLoading"
        :row-headers="ROW_HEADERS"
      >
        <template #title>
          Previous period {{ previousPeriodString }}
        </template>
      </MgmtSummaryTable>
    </div>
  </section>
</template>

<style lang="scss">
.mgmt-summary-tab {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: 40% 3% minmax(0, 1fr);
  gap: 8px;
  height: 100%;
  padding: 8px;
  min-height: 0;
  background-color: rgba(0,0,0,0.05);

  &__item-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: white;

    @include dark-inline-skeleton($percent: 100);

    &--overall-pie {
      grid-column: 2 / 7;
    }

    &--expl-widget {
      grid-column: 7 / 12;
    }

    &--map {
      grid-column: 12 / 20;
    }

    &--clock {
      grid-column: 20 / 25;
    }

    &--table-1 {
      grid-row: 3 / -1;
      grid-column: 3 / 13;
    }

    &--table-2 {
      grid-row: 3 / -1;
      grid-column: 14 / 24;
    }

    &--table-2,
    &--table-1 {
      background-color: transparent;
    }

    &--scrollable {
      overflow-y: auto;
      min-height: 0;

      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
