<script setup>
import { 
  ref,
  defineProps,
  onMounted,
  inject,
  onBeforeUnmount,
  onDeactivated,
  defineAsyncComponent,
  toRefs,
} from "vue";
import XSelect from "@/components/basic/XSelect.vue";
import PieWithLineChart from "@/components/dashboard/charts/PieWithLineChart.vue";
import LineChart from "@/components/dashboard/charts/LineChart.vue";
import CurrentTimeBlock from "@/components/dashboard/other/CurrentTimeBlock.vue";
import StackedBarChart2 from "@/components/dashboard/charts/StackedBarChart2.vue";
import UiTable from "@/components/basic/tables/UiTable.vue";
import UiIconLink from "@/components/basic/links/UiIconLink.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import ExplorerUsageWidget from "@/components/dashboard/charts/ExplorerUsageWidget/index.vue"

import { useAttachTabData } from "@/composition/dashboard/use-attach-tab-data";
import { useAttachMiniTable, TEST_RESULTS_COLUMNS_MINI } from "@/composition/dashboard/use-test-results-mini-table";
import { useDashboardFilters } from "@/composition/dashboard/use-dashboard-filters";
import { DEFAULT_PARAMS } from "@/composition/tables/use-table-api";
import { TEST_STEP_STATUS_LEGEND_TITLES, TEST_STEP_STATUS_MAP, DEFAULT_MAP_OPTIONS } from "@/composition/dashboard/constants";
import TEST_STATUS_CONFIG from "@/cfg/testStatus";
import { DEFAULT_RANGE } from "@/composition/filtering-components/use-time-range-select"
import { shaKey } from "@/js/helper";
import { useRouter } from "vue-router/composables";
import { unixToDateTimeString } from '@/js/general';

const LeafletDotsMap = defineAsyncComponent(() => import(/* webpackChunkName: "leaflet-dots-map" */ "@/components/dashboard/charts/LeafletDotsMap.vue"));

const props = defineProps({
  range: {
    type: Object,
    default: () => DEFAULT_RANGE,
  },
  mccs: {
    type: Array,
    default: () => ([])
  },
  mncs: {
    type: Object,
    default: () => ({})
  }
});

const { mccs, range, mncs } = toRefs(props);

const {
  openDialog
} = inject("DialogsRoot");

const {
  addToRefreshQueue,
  removeFromRefreshQueue,
} = inject("DashboardView2");

const isLoading = ref(true);

const {
  currentMCC,
  mccReqParam,
  mccSelectOptions,
  getCountryCodeByMCC,
  getOperatorByMNC,
  currentRat,
  ratReqParam,
  ratSelectOptions,
  currentTestStatus,
  testStatusReqParam,
  testStatusSelectOptions,
  rangeInSecForReq,
} = useDashboardFilters({ mccListRef: mccs, mncDictRef: mncs, rangeRef: range });

const {
  attachmentsDataStackedBar,
  attachmentsDataForMap,
  attachmentsOverallDataPieLine,
  attachments5GDataPieLine,
  attachments4GDataPieLine,
  attachments3GDataPieLine,
  attachments2GDataPieLine,
  attachmentsDataLine,
  runAccumulativeFetch,
} = useAttachTabData();

const {
  updateTableParams,
  tableParams,
  attachmentsTable,
  isAttachmentsTableLoading,
  meta,
} = useAttachMiniTable({ 
  params: {
    ...DEFAULT_PARAMS,
    from: rangeInSecForReq.value.from,
    to: rangeInSecForReq.value.to,
    mcc: mccReqParam.value,
    rat: ratReqParam.value,
    result: testStatusReqParam.value,
  }
});

const fetchChartsData = async (_range) => {
  await runAccumulativeFetch({ range: _range, mcc: mccReqParam.value, rat: ratReqParam.value, testStatusId: testStatusReqParam.value });
};
const updateDashboard = async (newTableParams) => {
  isLoading.value = true;
  try {
    await Promise.all([
      updateTableParams(newTableParams),
      fetchChartsData(rangeInSecForReq.value),
    ]);
  } finally {
    isLoading.value = false;
  }
};

const updateMCC = async (v) => {
  currentMCC.value = v;
  await updateDashboard({ ...tableParams.value, mcc: mccReqParam.value })
};

const updateRAT = async (v) => {
  currentRat.value = v;
  await updateDashboard({ ...tableParams.value, rat: ratReqParam.value })
};

const updateTestStatus = async (v) => {
  currentTestStatus.value = v;
  await updateDashboard({ ...tableParams.value, result: testStatusReqParam.value })
};


addToRefreshQueue("attach-tab-data", async ({ isRangeChange }) => {
  if (isRangeChange) {
    isLoading.value = true;
  }
  try {
    await Promise.all([
      updateTableParams({ ...tableParams.value, from: rangeInSecForReq.value.from, to: rangeInSecForReq.value.to }, { isSilent: !isRangeChange }),
      fetchChartsData(rangeInSecForReq.value)
    ]);
  } finally {
    isLoading.value = false;
  }
});

const openExplorerDialog = async ({ id }) => {
  if (!id) {
    return;
  }
  await openDialog("ExplorerDialog", { value: true, explorerId: id });
};

const router = useRouter();
const getTestInfoPath = (id) => {
  const idKey = shaKey(id.toString());
  return { name: "test-info", params: { id, idKey  } };
};
const openInNewTab = ({ href }) => {
  window.open(href, "_blank");
};
const openTestPage = ({ testQueueID: id }) => {
  const route = router.resolve(getTestInfoPath(id))
  openInNewTab({ href: route.href });
};

onMounted(async () => {
  await updateDashboard({ ...tableParams.value, to: rangeInSecForReq.value.to, from: rangeInSecForReq.value.from })
});


onDeactivated(() => {
  removeFromRefreshQueue("attach-tab-data");
});
onBeforeUnmount(() => {
  removeFromRefreshQueue("attach-tab-data");
});
</script>

<template>
  <section class="dashboard-w-table">
    <div class="dashboard-w-table__no-scroll-container">
      <div class="dashboard-w-table__top-box">
        <h2 class="dashboard-w-table__tab-title">
          Network Attach
        </h2>

        <div class="dashboard-w-table__tab-controls-box">
          <XSelect
            class="dashboard-w-table__filter-select"
            :value="currentMCC"
            label="MCC"
            item-value="mcc"
            item-text="countryCode"
            autocomplete
            :items="mccSelectOptions"
            @input="(v) => updateMCC(v)"
          />

          <XSelect
            class="dashboard-w-table__filter-select"
            :value="currentRat"
            label="RAT"
            autocomplete
            :items="ratSelectOptions"
            @input="(v) => updateRAT(v)"
          />

          <XSelect
            class="dashboard-w-table__filter-select"
            :value="currentTestStatus"
            label="Status"
            autocomplete
            :items="testStatusSelectOptions"
            item-value="key"
            item-text="value"
            @input="(v) => updateTestStatus(v)"
          />
        </div>
      </div>

      <div class="dashboard-w-table__scrollable-grid">
        <div class="dashboard-w-table__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="attachmentsOverallDataPieLine.line"
            :pieData="attachmentsOverallDataPieLine.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Overall' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="attachments5GDataPieLine.line"
            :pieData="attachments5GDataPieLine.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: '5G' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="attachments4GDataPieLine.line"
            :pieData="attachments4GDataPieLine.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: '4G' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="attachments3GDataPieLine.line"
            :pieData="attachments3GDataPieLine.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: '3G' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="attachments2GDataPieLine.line"
            :pieData="attachments2GDataPieLine.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: '2G' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <CurrentTimeBlock lang="en" :clock-size="150" />
        </div>

        <div class="dashboard-w-table__grid-item  dashboard-w-table__grid-item--1-5">
          <LeafletDotsMap
            v-if="!isLoading"
            :markers="attachmentsDataForMap.data"
            :categories-config-map="TEST_STEP_STATUS_MAP"
            :map-options="DEFAULT_MAP_OPTIONS"
            @marker-click="(marker) => openTestPage(marker)"
          />
        </div>

        <div class="dashboard-w-table__grid-item dashboard-w-table__grid-item--5-8">
          <StackedBarChart2
            v-if="!isLoading && mccs.length"
            :chart-data="attachmentsDataStackedBar"
            :categories-map="TEST_STEP_STATUS_MAP"
            :get-categories-titles="(mcc) => getCountryCodeByMCC(mcc)"
            :get-network-name="(mcc, mnc) => getOperatorByMNC(mcc,mnc)"
            :echart-options="{
              title: { text: 'Attach by country' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item dashboard-w-table__grid-item--8-11">
          <LineChart 
            v-if="!isLoading"
            :data="attachmentsDataLine"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Test Results' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="dashboard-w-table__grid-item">
          <ExplorerUsageWidget
            v-if="!isLoading"
            :range="rangeInSecForReq"
          />
        </div>

        <div 
          class="dashboard-w-table__grid-item  dashboard-w-table__grid-item--take-row  dashboard-w-table__grid-item--just-cont-between"
        >
          <div class="dashboard-w-table__table-scroll-box">
            <UiTable
              class="dashboard-w-table__lazy-table"
              :columns="TEST_RESULTS_COLUMNS_MINI"
              :items="attachmentsTable"
              is-with-skeleton
              :is-loading="isAttachmentsTableLoading"
              :sortBy="tableParams.sortBy"
              :sortDesc="tableParams.descending"
              :skeleton-rows="tableParams.itemsPerPage"
              @update:sorting="({ sortBy, sortDesc }) => updateTableParams({ ...tableParams, sortBy, descending: sortDesc })"
            >
              <template #cell(testCase-name)="{ cellValue, item }">
                <UiIconLink
                  :to="getTestInfoPath(item.id)"
                  custom
                  :with-icon="false"
                  @navigate="({ href }) => openInNewTab({ href })"
                >
                  {{ cellValue }}
                </UiIconLink>
              </template>

              <template #cell(subscriber)="{ cellValue }">
                <button 
                  class="dashboard-w-table__subscriber-table-cell"
                  @click="() => openExplorerDialog({ id: cellValue.id })"
                >
                  <span class="dashboard-w-table__subscriber-name">
                    {{ cellValue.name }}
                  </span>

                  {{ cellValue.alias ? `(${cellValue.alias})` : '' }}
                </button>
              </template>

              <template #cell(status)="{ cellValue }">
                <div class="dashboard-w-table__group-test-status">
                  <v-icon 
                    :color="TEST_STATUS_CONFIG[cellValue].color"
                  >
                    {{ TEST_STATUS_CONFIG[cellValue].icon }}
                  </v-icon>

                  {{ TEST_STATUS_CONFIG[cellValue].text }}
                </div>
              </template>

              <template #cell(startTime)="{ cellValue }">
                {{ unixToDateTimeString(cellValue) }}
              </template>

              <template #cell(endTime)="{ cellValue }">
                {{ unixToDateTimeString(cellValue) }}
              </template>
            </UiTable>
          </div>

          <div class="dashboard-w-table__table-controls">
            <PaginationBlock
              :total-count="meta.totalCount"
              :current-page="meta.page"
              :items-per-page="meta.itemsPerPage"
              @update:current-page="(pageN) => updateTableParams({ ...tableParams, page: pageN })"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

