<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  GraphicComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { merge } from "lodash-es";
import { vuetifyConfig } from "@/plugins/vuetify";

use([
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  GraphicComponent,
  BarChart,
  CanvasRenderer,
]);

// type mcc = number;
// type mcn = number;
// type status = number;
// type ChartData = {
//   categories: Array<Record<mcc, Array<mcc>>
 // data: Record<status, Array<Array<number>>
// }

const props = defineProps({
  echartOptions: {
    type: Object,
    default: () => ({}),
  },
  // ChartData
  chartData: {
    type: Object,
    default: () => ({ categories: [], data: null }),
  },
  categoriesMap: {
    // Vue 2 doesn"t treat Map"s, but this values stays static
    type: Map,
    default: () => new Map(),
  },
  getCategoriesTitles: {
    type: Function,
    default: (list) => list,
  },
  getNetworkName: {
    type: Function,
    default: () => "Unknown",
  },
  barWidth: {
    type: Number,
    default: 15
  }
});

const dummyData = {
  1: [120, 132, 101, 134, 90, 230, 210],
};

const isDummyData = computed(() => !props.chartData.data || Object.values(props.chartData.data).every(arr => Array.isArray(arr) && arr.length === 0));

const flattenedCategories = computed(() => {
  if (!props.chartData.categories || props.chartData.categories.length === 0) {
    return [];
  }

  // Create a flat structure with format: [{ mcc: "208', mnc: '37", index: [0, 0] }, ...]
  const result = [];
  props.chartData.categories.forEach((countryObj, countryIndex) => {
    const mcc = Object.keys(countryObj)[0];
    countryObj[mcc].forEach((mnc, mncIndex) => {
      result.push({
        mcc,
        mnc,
        index: [countryIndex, mncIndex],
        country: props.getCategoriesTitles(mcc),
        networkName: props.getNetworkName(mcc, mnc)
      });
    });
    
    // Add an empty slot between countries
    if (countryIndex < props.chartData.categories.length - 1) {
      result.push({ isEmpty: true });
    }
  });
  
  return result;
});


const transformedSeriesData = computed(() => {
  if (isDummyData.value) {
    return [];
  }
  
  const result = {};
  
  Object.entries(props.chartData.data).forEach(([statusId, countryData]) => {
    const intId = parseInt(statusId);
    if (!props.categoriesMap.has(intId)) {
      return;
    }
    
    const { text, color } = props.categoriesMap.get(intId);
    if (!result[statusId]) {
      result[statusId] = {
        name: text,
        color: vuetifyConfig.theme.themes.light[color],
        data: []
      };
    }
    
    flattenedCategories.value.forEach(cat => {
      if (cat.isEmpty) {
        // Add 0 for empty slots between countries
        result[statusId].data.push(0);
      } else {
        const [countryIndex, mncIndex] = cat.index;
        // Get the value from the original data structure
        const value = countryData[countryIndex]?.[mncIndex] || 0;
        result[statusId].data.push(value);
      }
    });
  });
  
  return Object.values(result);
});

const createSeriesData = (data) => {
  if (!data) {
    return { series: [], max: 0 };
  }
  
  if (!isDummyData.value) {
    let maxValue = 0;
    const series = transformedSeriesData.value.map(item => {
      const maxInSeries = Math.max(...item.data);
      maxValue = Math.max(maxValue, maxInSeries);
      
      return {
        name: item.name,
        type: "bar",
        barWidth: props.barWidth,
        stack: "stack",
        emphasis: { focus: "series" },
        itemStyle: {
          color: item.color,
          opacity: isDummyData.value ? 0.2 : 1,
        },
        data: item.data
      };
    });
    
    return { series, max: maxValue };
  }
  
  // Original dummy data handling
  let maxValue = 0;
  const series = [];

  for (const [testStatusId, testsAmount] of Object.entries(data)) {
    const intId = parseInt(testStatusId);
    if (!props.categoriesMap.has(intId)) {
      continue;
    }

    const { text, color } = props.categoriesMap.get(intId);
    series.push({
      name: text,
      type: "bar",
      barWidth: props.barWidth,
      stack: "stack",
      itemStyle: {
        color: vuetifyConfig.theme.themes.light[color],
        opacity: isDummyData.value ? 0.2 : 1,
      },
      data: testsAmount,
    });

    maxValue = testsAmount.reduce((lastMax, n) => Math.max(lastMax, n), maxValue);
  }

  return {
    series,
    max: maxValue,
  };
};

const xAxisLabels = computed(() => {
  return flattenedCategories.value.map(cat => {
    if (cat.isEmpty) return "";
    return `${cat.country}\n${cat.networkName}\n${cat.mnc}`
  });
});

const options = computed(() => {
  const { series, max: maxYAxisVal } = createSeriesData(isDummyData.value ? dummyData : props.chartData.data);

  const baseOptions = {
    tooltip: {
      appendToBody: true,
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        if (params[0].axisValueLabel === "") {
          return "";
        }
        
        const fullLabel = params[0].axisValueLabel || "";
        const parts = fullLabel.split("\n");
        const country = parts[0] || "";
        const network = parts[1] || "";
        
        let tooltipStr = network ? `${network} (${country})` : country;
        
        params.forEach(function (item) {
          if (item.value !== 0) {
            tooltipStr += `<br/>${item.marker}${item.seriesName}: ${item.value}`;
          }
        });
        return tooltipStr;
      },
    },
    toolbox: {
      right: 10,
      feature: {
        saveAsImage: {
          show: true,
        },
      },
    },
    legend: {
      type: "scroll",
      bottom: 0,
      icon: "pin",
      left: 0,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        data: xAxisLabels.value,
        axisLine: {
          lineStyle: {
            opacity: isDummyData.value ? 0.2 : 1,
          },
        },
        axisLabel: {
          interval: 0,
          formatter(value) {
            if (!value) return "";
            const parts = value.split("\n");
            const mnc = parts[2].padStart(2, "0");
            
            return mnc;
          }
        }
      },
      {
        type: "category",
        position: "bottom",
        offset: 20,
        axisTick: { show: false },
        axisLine: { show: false },
        data: xAxisLabels.value,
        axisLabel: {
          interval: 0,
          formatter(value, index) {
            if (!value) return " ";
            
            // Show country code only for the first MNC in each country
            const cat = flattenedCategories.value[index];
            if (!cat || cat.isEmpty) return "";
            
            // Get all categories with this country
            const countryCats = flattenedCategories.value.filter(c => 
              !c.isEmpty && c.country === cat.country
            );
            
            if (countryCats.length > 0) {
              // Only show country name for the middle entry of each country group
              // const idx = Math.floor(countryCats.length / 2);
              // only show country under first entry
              const idx = 0;
              if (countryCats[idx]?.index[0] === cat.index[0] && 
                  countryCats[idx]?.index[1] === cat.index[1]) {
                return cat.country;
              }
            }
            
            return "";
          }
        }
      }
    ],
    yAxis: {
      type: "value",
      min: 0,
      max: maxYAxisVal,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: (v) => {
          if (v === 0 || v === maxYAxisVal) {
            return v;
          } else {
            return "";
          }
        },
      },
    },
    series,
  };

  if (isDummyData.value) {
    baseOptions.tooltip.show = false;
    baseOptions.toolbox.feature.saveAsImage.show = false;
    baseOptions.silent = true;
    baseOptions.animation = false;
    baseOptions.legend.show = false;
    baseOptions.smooth = true;

    baseOptions.graphic = [
      {
        type: "text",
        left: "center",
        top: "40%",
        style: {
          text: "No data in the selected\ntime range and filters",
          font: "bold 14px Arial",
          textAlign: "center",
          backgroundColor: "rgba(255,255,255,0.7)",
          padding: [3, 10, 5, 10],
          lineHeight: 20,
        },
        z: 8,
      },
    ]
  }

  return merge(baseOptions, props.echartOptions);
});

const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  await new Promise((r) => setTimeout(r, 500));
  isLoading.value = false;
});

</script>

<template>
  <div
    ref="container"
    class="stacked-bar-chart"
  >
    <VChart
      v-if="!isLoading"
      class="stacked-bar-chart__chart"
      ref="chart"
      :option="options"
      :autoresize="true"
    />
  </div>
</template>

<style lang="scss">
.stacked-bar-chart {
  height: 100%;
  width: 100%;
  position: relative;
  @include dark-inline-skeleton();

  &__chart {
    width: 100%;
    height: 100%;
  }
}
</style>
