var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoading && !_vm.isWithSkeleton)?_c('div',{staticClass:"ui-table__loading-table-box"},[_c('table',{staticClass:"ui-table ui-table--is-loading",style:(`--total-fixed-width: ${_setup.totalFixedWidth}; --auto-column-count: ${_setup.autoColumnCount}`)},[_c('colgroup',_vm._l((_vm.columns),function(col){return _c('col',{key:col.key,class:{
          'ui-table__col': true,
          'ui-table__col--fixed': col.width,
          'ui-table__col--auto': !col.width
        },style:(col.width ? { '--col-width': `${col.width}px` } : {})})}),0),_c('thead',{staticClass:"ui-table__thead"},[_vm._t("top-headers"),_c('tr',{staticClass:"ui-table__row ui-table__row--head"},_vm._l((_vm.columns),function(col){return _c('th',{key:col.key,staticClass:"ui-table__cell ui-table__cell--head"},[_c('div',{class:{
              'ui-table__inner-cell': true,
              'ui-table__inner-cell--head': true,
              'ui-table__inner-cell--sortable': col.sortable,
              'ui-table__inner-cell--active-head': col.sortKey ? col.sortKey === _vm.sortBy : col.key === _vm.sortBy,
              'ui-table__inner-cell--descending-head': (col.sortKey ? col.sortKey === _vm.sortBy : col.key === _vm.sortBy) && _vm.sortDesc,
            }},[_vm._t(`header(${_setup.makeSlotName(col.key)})`,function(){return [_vm._v(" "+_vm._s(col.name)+" ")]},{"col":col}),(col.sortable)?_c('div',{staticClass:"ui-table__sort-box"},[_c('v-icon',{staticClass:"ui-table__sort-arrow",attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")])],1):_vm._e()],2)])}),0)],2)]),_c('div',{staticClass:"ui-table__loader-container"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100","width":"10"}})],1)]):_c('table',{class:{
    'ui-table': true,
    'ui-table--horizontal-scroll': _vm.withHorizontalScroll,
  },style:(`--total-fixed-width: ${_setup.totalFixedWidth}; --auto-column-count: ${_setup.autoColumnCount}`)},[_c('colgroup',_vm._l((_vm.columns),function(col){return _c('col',{key:col.key,class:{
        'ui-table__col': true,
        'ui-table__col--fixed': col.width,
        'ui-table__col--auto': !col.width
      },style:(col.width ? { '--col-width': `${col.width}px` } : {})})}),0),_c('thead',{staticClass:"ui-table__thead"},[_vm._t("top-headers"),_c('tr',{staticClass:"ui-table__row ui-table__row--head"},_vm._l((_vm.columns),function(col){return _c('th',{key:col.key,staticClass:"ui-table__cell ui-table__cell--head"},[_c('div',{class:{
            'ui-table__inner-cell': true,
            'ui-table__inner-cell--head': true,
            'ui-table__inner-cell--sortable': col.sortable,
            'ui-table__inner-cell--active-head': col.sortKey ? col.sortKey === _vm.sortBy : col.key === _vm.sortBy,
            'ui-table__inner-cell--descending-head': (col.sortKey ? col.sortKey === _vm.sortBy : col.key === _vm.sortBy) && _vm.sortDesc,
          },on:{"click":() => _setup.toggleSort(col)}},[_vm._t(`header(${_setup.makeSlotName(col.key)})`,function(){return [_vm._v(" "+_vm._s(col.name)+" ")]},{"col":col}),(col.sortable)?_c('div',{staticClass:"ui-table__sort-box"},[_c('v-icon',{staticClass:"ui-table__sort-arrow",attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")])],1):_vm._e()],2)])}),0)],2),(!_vm.isLoading && _vm.items.length)?_c('tbody',{staticClass:"ui-table__tbody"},_vm._l((_vm.items),function(item,itemIndex){return _c('tr',{key:itemIndex,class:{
        'ui-table__row': true,
        'ui-table__row--disabled': _vm.checkDisabledRow(item, itemIndex),
      }},_vm._l((_vm.columns),function(col,colIndex){return _c('td',{key:colIndex,staticClass:"ui-table__cell"},[_c('div',{class:{
            'ui-table__inner-cell': true,
          }},[_vm._t(`cell(${_setup.makeSlotName(col.key)})`,function(){return [_vm._v(" "+_vm._s(_setup.getByKey(item, col.key) || "")+" ")]},{"item":item,"col":col,"cellValue":_setup.getByKey(item, col.key) || ''})],2)])}),0)}),0):(_vm.isLoading && _vm.isWithSkeleton)?_c('tbody',_vm._l((_vm.skeletonRows),function(row){return _c('tr',{key:row},_vm._l((_vm.columns.length),function(colIndex){return _c('td',{key:colIndex,staticClass:"ui-table__skeleton-cell"},[_c('div',{staticClass:"ui-table__skeleton-cell-inner"})])}),0)}),0):(!_vm.isLoading && !_vm.items.length)?_c('tbody',[_c('tr',[_c('td',{staticClass:"ui-table__cell ui-table__cell--empty",attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"ui-table__inner-cell ui-table__inner-cell--empty-txt"},[_vm._t("empty-table-text",function(){return [_vm._v(" No records found ")]})],2)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }