var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"overview-tab"},[_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.testResultsPieLine.line,"pieData":_setup.testResultsPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Overall' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES }
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.attachDataPieLine.line,"pieData":_setup.attachDataPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Attach' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES }
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.voiceDataPieLine.line,"pieData":_setup.voiceDataPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Voice' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES }
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.dataSessionsPieLine.line,"pieData":_setup.dataSessionsPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Data' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES },
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.smsDataPieLine.line,"pieData":_setup.smsDataPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'SMS' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES },
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[_c(_setup.CurrentTimeBlock,{attrs:{"lang":"en","clock-size":150}})],1),_c('div',{staticClass:"overview-tab__item-grid overview-tab__item-grid--1-3"},[(!_setup.isLoading)?_c(_setup.LeafletDotsMap,{attrs:{"markers":_setup.testResultsMap.data,"categories-config-map":_setup.TEST_STEP_STATUS_MAP,"map-options":_setup.DEFAULT_MAP_OPTIONS},on:{"marker-click":(marker) => _setup.openTestPage(marker)}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid overview-tab__item-grid--3-5"},[(!_setup.isLoading && _setup.mccs.length)?_c(_setup.StackedBarChart2,{attrs:{"chart-data":_setup.testResultsStackedBar,"categories-map":_setup.TEST_STEP_STATUS_MAP,"get-categories-titles":(mcc) => _setup.getCountryCodeByMCC(mcc),"get-network-name":(mcc, mnc) => _setup.getOperatorByMNC(mcc,mnc),"echart-options":{
        title: { text: 'Tests by Country' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES }
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[(!_setup.isLoading)?_c(_setup.PieWithLineChart,{attrs:{"lineData":_setup.speedDataPieLine.line,"pieData":_setup.speedDataPieLine.pie,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Speed' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES },
      }}}):_vm._e()],1),_c('div',{staticClass:"overview-tab__item-grid"},[_c(_setup.ExplorerUsageWidget,{attrs:{"range":_setup.rangeInSecForReq}})],1),_c('div',{staticClass:"overview-tab__item-grid overview-tab__item-grid--take-row"},[(!_setup.isLoading)?_c(_setup.LineChart,{attrs:{"data":_setup.testResultsLineChart,"categories-map":_setup.TEST_STEP_STATUS_MAP,"echart-options":{
        title: { text: 'Test Step Results' },
        legend: { data: _setup.TEST_STEP_STATUS_LEGEND_TITLES },
      }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }