<script setup>
import { defineProps, inject, defineAsyncComponent, ref, onMounted, onBeforeUnmount, onDeactivated, toRefs } from "vue";
import PieWithLineChart from "@/components/dashboard/charts/PieWithLineChart.vue";
import LineChart from "@/components/dashboard/charts/LineChart.vue";
import CurrentTimeBlock from "@/components/dashboard/other/CurrentTimeBlock.vue";
import StackedBarChart2 from "@/components/dashboard/charts/StackedBarChart2.vue";
import ExplorerUsageWidget from "@/components/dashboard/charts/ExplorerUsageWidget/index.vue"
import { useOverviewTabData } from "@/composition/dashboard/use-overview-tab-data";
import { TEST_STEP_STATUS_LEGEND_TITLES, TEST_STEP_STATUS_MAP, DEFAULT_MAP_OPTIONS } from "@/composition/dashboard/constants";
import { useDashboardFilters } from "@/composition/dashboard/use-dashboard-filters";
import { shaKey } from "@/js/helper";
import { useRouter } from "vue-router/composables";

import { DEFAULT_RANGE } from "@/composition/filtering-components/use-time-range-select";

const LeafletDotsMap = defineAsyncComponent(() => import(/* webpackChunkName: "leaflet-dots-map" */ "@/components/dashboard/charts/LeafletDotsMap.vue"));

const props = defineProps({
  range: {
    type: Object,
    default: () => DEFAULT_RANGE,
  },
  mccs: {
    type: Array,
    default: () => ([])
  },
  mncs: {
    type: Object,
    default: () => ({})
  }
});

const { mccs, range, mncs } = toRefs(props);

const {
  getCountryCodeByMCC,
  getOperatorByMNC,
  rangeInSecForReq,
} = useDashboardFilters({ mccListRef: mccs, mncDictRef: mncs, rangeRef: range });

const {
  testResultsPieLine,
  attachDataPieLine,
  dataSessionsPieLine,
  voiceDataPieLine,
  smsDataPieLine,
  testResultsStackedBar,
  testResultsLineChart,
  testResultsMap,
  speedDataPieLine,
  runAccumulativeFetch,
} = useOverviewTabData();

const {
  addToRefreshQueue,
  removeFromRefreshQueue,
} = inject("DashboardView2");
const isLoading = ref(true);

addToRefreshQueue("overview-tab-data", async ({ isRangeChange }) => {
  if (isRangeChange) {
    isLoading.value = true;
  }
  try {
    await runAccumulativeFetch({ range: rangeInSecForReq.value });
  } finally {
    isLoading.value = false;
  }
});

const router = useRouter();
const openTestPage = ({ testQueueID: id }) => {
  const idKey = shaKey(id.toString());
  const route = router.resolve({ name: "test-info", params: { id, idKey } })
  window.open(route.href, "_blank");
};

onMounted(async () => {
  try {
    await runAccumulativeFetch({ range: rangeInSecForReq.value });
  } finally {
    isLoading.value = false;
  }
});

onDeactivated(() => {
  removeFromRefreshQueue("overview-tab-data");
});
onBeforeUnmount(() => {
  removeFromRefreshQueue("overview-tab-data");
});
</script>

<template>
  <div class="overview-tab">
    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="testResultsPieLine.line"
        :pieData="testResultsPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Overall' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="attachDataPieLine.line"
        :pieData="attachDataPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Attach' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="voiceDataPieLine.line"
        :pieData="voiceDataPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Voice' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="dataSessionsPieLine.line"
        :pieData="dataSessionsPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Data' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES },
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="smsDataPieLine.line"
        :pieData="smsDataPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'SMS' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES },
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <CurrentTimeBlock lang="en" :clock-size="150" />
    </div>

    <div class="overview-tab__item-grid overview-tab__item-grid--1-3">
      <LeafletDotsMap
        v-if="!isLoading"
        :markers="testResultsMap.data"
        :categories-config-map="TEST_STEP_STATUS_MAP"
        :map-options="DEFAULT_MAP_OPTIONS"
        @marker-click="(marker) => openTestPage(marker)"
      />
    </div>

    <div class="overview-tab__item-grid overview-tab__item-grid--3-5">
      <StackedBarChart2
        v-if="!isLoading && mccs.length"
        :chart-data="testResultsStackedBar"
        :categories-map="TEST_STEP_STATUS_MAP"
        :get-categories-titles="(mcc) => getCountryCodeByMCC(mcc)"
        :get-network-name="(mcc, mnc) => getOperatorByMNC(mcc,mnc)"
        :echart-options="{
          title: { text: 'Tests by Country' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <PieWithLineChart
        v-if="!isLoading"
        :lineData="speedDataPieLine.line"
        :pieData="speedDataPieLine.pie"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Speed' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES },
        }"
      />
    </div>

    <div class="overview-tab__item-grid">
      <ExplorerUsageWidget
        :range="rangeInSecForReq"
      />
    </div>

    <div class="overview-tab__item-grid  overview-tab__item-grid--take-row">
      <LineChart 
        v-if="!isLoading"
        :data="testResultsLineChart"
        :categories-map="TEST_STEP_STATUS_MAP"
        :echart-options="{
          title: { text: 'Test Step Results' },
          legend: { data: TEST_STEP_STATUS_LEGEND_TITLES },
        }"
      />
    </div>
  </div>
</template>

<style lang="scss">
.overview-tab {
  position: relative;
  display: grid;
  grid-template-rows: 40% 40% minmax(0, 1fr);
  grid-template-columns: repeat(5, 1fr) 250px;
  gap: 8px;
  height: 100%;
  padding: 8px;
  min-height: 0;
  background-color: rgba(0,0,0,0.05);

  &__item-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: white;

    @include dark-inline-skeleton($percent: 100);

    &--1-3 {
      grid-column: 1 / 3;
    }

    &--3-5 {
      grid-column: 3 / 5;
    }

    &--take-row {
      grid-column: 1 / -1;
    }
  }
}
</style>
